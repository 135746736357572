.year-picker {
   font-weight: bold !important;
   font-size: 1rem !important;
   border-radius: 24px !important;
}

textarea {
   resize: vertical !important;
}

.wizard-transition {
   width: 60vw;
}

.wizard-transition-enter {
   transform: translateX(10000px);
}

.wizard-transition-enter-active {
   position: absolute;
}

.wizard-transition-enter-done {
   transition: transform 200ms !important;
}

.wizard-transition-exit {
   position: relative;
}

.wizard-transition-exit-active {
   opacity: .1;
   transition: opacity 200ms !important;
}

.wizard-transition-exit-done {
   transition: opacity 200ms !important;
   display: none;
}

.carousel-image { position: relative; }

.carousel-image figcaption {
   position: absolute;
   bottom: 0.5em;
   left: 0;
   box-sizing: border-box;
   width: 100%;
   padding: 10px;
   color: white;
   text-align: center;
   background: rgba(0, 0, 0, 0.6);
   word-wrap: break-word;
}

/* .MuiCard-root .bottomAlignActions {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
} */
